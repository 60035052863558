.ck-powered-by-balloon {
    display: none !important;
}

.ck.ck-editor.ck-reset {
    width: 100% !important;
}

.ck-content {
    /* border: inherit !important; */
}

.ck-content {
    min-height: 150px !important;
    width: 100% !important;
}