.ck.ck-editor__top.ck-reset_all {
    display: none;
}

.ck-content {
    /* border: none !important; */
}

.ck.ck-editor.ck-reset {
    width: 100% !important;
}

.ck-content {
    min-height: 150px;
    width: 100%;
}