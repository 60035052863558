* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

audio {
  width: 300px !important;
  height: 54px !important;
}

.audio-full-width {
  width: 100% !important;
}

.audio-default {
  width: unset;
}

.list {
  margin-bottom: 4em;
}

.list li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.overlay {
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.50);
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
/* 
.dialog-box {
  pointer-events: all;
  border: 1px solid black;
  background-color: lightgrey;
  padding: 0.5em 0.5em 0.5em 0.5em;
}

.dialog-box .title {
  font-weight: bold;
  padding-bottom: 2px;
}

.dialog-box .title .fa-window-close {
  font-size: 1.1em;
  line-height: 100%;
  float: right;
  cursor: pointer;
}

.dialog-box .video-viewport {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid black;
  box-sizing: content-box;
  background-color: black;
  transition: width 0.25s, height 0.25s;
}

.dialog-box .video-viewport video {
  transition: opacity 0.25s;
  opacity: 1;
}

.dialog-box .video-viewport .fa-stack {
  font-size: 6em;
  color: lightgray;
}

.dialog-box .video-viewport .fa-stack .fa-ban {
  color: red;
}

.dialog-box .video-viewport.initiating video {
  opacity: 0;
}

.dialog-box .controls {
  display: flex;
  padding-top: 0.5em;
}

.dialog-box .controls .devices {
  flex: 1 1 8em;
  text-align: left;
}

.dialog-box .controls .devices select {
  padding: 1px 3px 1px 3px;
}

.dialog-box .controls .duration {
  flex: 1 1 8em;
  text-align: left;
  padding: 3px 2px 0px 2px;
}

.dialog-box .controls .volume {
  flex: 1 1 auto;
  text-align: center;
}

.dialog-box .controls .volume .fa {
  display: inline-block;
  width: 1.25em;
  vertical-align: middle;
  text-align: left;
}

.dialog-box .controls .volume .volume-bar-frame {
  display: inline-block;
  border: 1px solid black;
  width: 5em;
  vertical-align: middle;
}

.dialog-box .controls .volume .volume-bar-frame .volume-bar {
  height: 0.5em;
  background-color: gray;
}

.dialog-box .controls .volume .volume-bar-frame .volume-bar.capturing {
  background-color: green;
}

.dialog-box .controls .buttons {
  flex: 1 1 8em;
  text-align: right;
}

.dialog-box .controls .buttons button {
  min-width: 5em;
  margin-left: 0.2em;
} */

/* Custom styles for TimePicker */
.ant-picker-time-panel-cell:hover .ant-picker-time-panel-cell-inner{
  background-color: #3182ce !important; /* Hover background color */
  color: white !important;
}

.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
  background-color: #3182ce !important; /* Selected cell background color */
  color: white !important; /* Text color for selected cell */
}

.ant-picker .ant-picker-input input {
  font-size: 1rem !important;
}

.ant-picker-clear {
  display: none !important;
}

#historyDatePicker{
  text-align: center !important;
  background-color: #3182ce !important;
  color: white !important;
}

#historyDatePicker + .ant-picker-suffix {
  display: none !important;
}